
import { defineComponent } from "vue";

export default defineComponent({
  name: "MessageOut",
  props: {
    image: {type: String, default: ""},
    time: {type: String, default: ""},
    text: {type: String, default: ""},
  },
});
